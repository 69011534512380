/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, arrayOf, string, bool, array,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';

const styles = ({ breakpoints }) => ({
    root: {
        flexGrow: 1,
    },
    liLink: {
        display: 'inline-block',
        backgroundRepeat: 'no-repeat',
        padding: '0px 3px',
    },
    liALink: {
        width: 35,
        display: 'inherit',
        height: 35,
        textDecoration: 'none',
        marginRight: 5,
    },
    ulSocial: {
        float: 'right',
        margin: 'auto 160px',
    },
    [breakpoints?.down('md')]: {
        mobileSocial: {
            display: 'flex',
            textAlign: 'center',
            maxWidth: '280px',
            margin: '0 auto',
            padding: '20px 0px 10px 0px',
            '& li': {
                flexGrow: 1,
            },
        },
        ulSocial: {
            float: 'none',
            margin: '0px',
        },
    },
});
const SocialLinksBlock = ({ data: { social_link_ref }, classes }) => {
    const getLink = (socialIcon, index) => {
        if (socialIcon?.social_link) {
            const socialLink = socialIcon?.social_link || null;
            const icon = socialIcon?.icon || null;
            const title = socialLink?.title;
            const href = socialLink?.href;
            const url = icon?.url;
            if (title && href && url) {
                return (
                    <li className={classes.liLink} key={uid(title, index)}>
                        <Link className={classes.liALink} to={href} style={{ background: `url('${url}')` }}>&nbsp;</Link>
                    </li>
                );
            }
        }
        return null;
    };

    if (social_link_ref?.length) {
        return (
            <div className={classes.ulSocial}>
                <ul className={classes.mobileSocial}>
                    {social_link_ref.map((sl) => {
                        const group = sl?.group;
                        return ((group?.length) ? group.map((socialLinks, index) => (getLink(socialLinks, index))) : null);
                    })}
                </ul>
            </div>
        );
    }
    return null;
};
SocialLinksBlock.propTypes = {
    classes: object.isRequired,
    data: shape({
        left_panel_ref: array,
        social_link_ref: arrayOf(shape({
            locale: string,
            _content_type_uid: string,
            group: arrayOf(shape({
                social_link: shape({
                    title: string,
                    href: string,
                }),
                icon: shape({
                    uid: string,
                    content_type: string,
                    file_size: string,
                    filename: string,
                    url: string,
                    is_dir: bool,
                    parent_uid: string,
                    title: string,
                }),
            })),
            title: string,
            uid: string,
        })),
        viewport: arrayOf(shape({
            uid: string,
            _content_type_uid: string,
        })),
    }),
};

SocialLinksBlock.defaultProps = {
    data: {
        left_panel_ref: [],
        social_link_ref: [
            {
                locale: 'en-us',
                _content_type_uid: 'social_link',
                group: [
                    {
                        social_link: {
                            title: 'Twitter',
                            href: 'https://www.twitter.com/1800flowers',
                        },
                        icon: {
                            uid: 'bltd671c9980d779611',
                            content_type: 'image/png',
                            file_size: '18381',
                            filename: 'searchImage.png',
                            url: 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/bltd671c9980d779611/5d54150a6d05170a7c4ec7ba/searchImage.png',
                            is_dir: false,
                            parent_uid: null,
                            title: 'searchImage.png',
                        },
                    },
                    {
                        social_link: {
                            title: 'Facebook',
                            href: 'https://www.facebook.com/1800flowers',
                        },
                        icon: {
                            uid: 'bltd671c9980d779611',
                            content_type: 'image/png',
                            file_size: '18381',
                            filename: 'searchImage.png',
                            url: 'https://images.contentstack.io/v3/assets/bltdd99f24e8a94d536/bltd671c9980d779611/5d54150a6d05170a7c4ec7ba/searchImage.png',
                            is_dir: false,
                            parent_uid: null,
                            title: 'searchImage.png',
                        },
                    },
                ],
                title: 'Footer Social Links',
                uid: 'blt0eff9e4a3014faa8',
            },
        ],
        viewport: [
            {
                uid: 'bltda0506b9e461c30f',
                _content_type_uid: 'viewport',
            },
        ],
    },
};

export default withStyles(styles)(SocialLinksBlock);
