/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ReactMarkdown from 'react-markdown';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
    array, object, string, func, bool, any,
} from 'prop-types';

const styles = (theme) => ({
    title: {
        padding: '10px 15px 10px',
        fontFamily: theme.typography.fontFamily,
        textAlign: 'center',
        textTransform: 'uppercase',
        postion: 'relative',
        '& h6': {
            fontSize: '1rem;',
            fontWeight: '600',
            borderBottom: '2px solid',
            padding: '5px 0px',
            borderBottomColor: theme.palette.modalBg,
        },
    },

    dialogMain: {
        '& div': {
            '& div': {
                borderRadius: 'initial',
                maxWidth: '960px',
            },
        },
    },
    closeIcon: {
        position: 'absolute',
        top: '13px',
        right: '13px',
        cursor: 'pointer',
    },
    titleText: {
        color: theme.palette.modalTile,
    },
    dialogContent: {
        fontSize: '14px',
        color: theme.palette.grey1,
        wordBreak: 'break-all',
        '& pre': {
            '& code': {
                whiteSpace: 'pre-line',
            },
        },
        '& span img:nth-of-type(1)': {
            marginLeft: 'auto',
            display: 'flex',
        },
    },
});
const closeClick = (self, setDialog, close) => {
    setDialog({ close, self });
};

const TermsPrivacyModal = ({
    classes, title, data, show, self, setDialog,
}) => (
    <Dialog className={classes.dialogMain} open={show} aria-labelledby="form-dialog-title">
        <DialogTitle className={classes.title} id="form-dialog-title">
            <span className={classes.titleText}>
                {title}
            </span>
            <span role="presentation" onClick={() => { closeClick(self, setDialog, false); }}>
                <CloseIcon className={classes.closeIcon} />
            </span>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                <span className={classes.dialogContent}>
                    {data?.map((item) => (
                        item?.popup_title === title
                            ? (
                                <span key={`${item?.popup_title}`}>
                                    <ReactMarkdown source={item?.popup_content} />
                                </span>
                            )
                            : null
                    ))}
                </span>
            </DialogContentText>
        </DialogContent>
    </Dialog>
);

TermsPrivacyModal.propTypes = {
    classes: object.isRequired,
    show: bool.isRequired,
    data: array.isRequired,
    title: string.isRequired,
    setDialog: func.isRequired,
    self: any,
};

TermsPrivacyModal.defaultProps = {
    self: null,
};

export default withStyles(styles)(TermsPrivacyModal);
