/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, object,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    termsGoogleRecaptchat: ({ altColor }) => ({
        display: 'flex',
        margin: '0 auto',
        width: 'fit-content',
        fontSize: '.875rem',
        color: altColor,
        '& a': {
            color: altColor,
            textUnderlineOffset: '3px',
            textDecoration: 'underline !important',
            padding: '0 4px',
        },
        '@media (max-width: 1024px)': {
            display: 'block',
            textAlign: 'center',
            padding: '.5rem 0 1rem',
            color: theme.palette.black || '#4f4f4f',
            fontSize: '0.75rem',
            '& a': {
                color: `${theme.palette.black || '#4f4f4f'} !important`,
            },
        },
    }),
}));
const LegalRecaptchaLinks = ({
    brand, legalData, isRecaptchaCheckoutEnabled,
}) => {
    const domain = brand.domain;

    const getAltColor = () => {
        let color = '#1f1f1b'; // default
        legalData.alternate_text_color?.value?.forEach((o) => {
            if (o.key === domain) color = o.value;
        });
        return color;
    };

    const altColor = getAltColor();
    const classes = useStyles({ altColor });

    if (isRecaptchaCheckoutEnabled) {
        return (
            <div className={classes.termsGoogleRecaptchat}>
                <div>This site is protected by reCAPTCHA and the Google </div>
                <a target="_blank" href="https://policies.google.com/privacy" rel="noreferrer">Privacy Policy</a> and
                <a target="_blank" href="https://policies.google.com/terms" rel="noreferrer">Terms of Service</a> apply.
            </div>
        );
    }
    return null;
};

LegalRecaptchaLinks.propTypes = {
    brand: object.isRequired,
    legalData: object.isRequired,
    isRecaptchaCheckoutEnabled: bool.isRequired,
};

export default LegalRecaptchaLinks;
