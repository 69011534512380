/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    bool, object, string,
    shape,
} from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import FullCircleIcon from '@material-ui/icons/Brightness1';
import Button from '@material-ui/core/Button';
import memberDucks from '../../../../../state/ducks/Member/ducks';
import { isEmpty } from '../../utils/object';
import { getShopTheSite, resetShopTheSiteStorage } from '../../utils/shopTheSite';
import { getFeatureFlags } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getActiveABTests } from '../../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';

const styles = ({ typography, palette }) => ({
    giftListShopTheSiteBanner: {
        display: 'flex',
        color: 'white',
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%) translateY(0%)',
        width: 'calc(100% - 16px)',
        maxWidth: 400,
        padding: '8px 16px',
        backgroundColor: palette.skuPriceDiscountColor || '#374762', // TODO
        borderTopRightRadius: 2,
        borderTopLeftRadius: 2,
        alignItems: 'center',
        fontSize: '16px',
        zIndex: 2,
        '&:empty': {
            display: 'none',
        },
        '& .full-circle-icon': {
            flex: 'none',
            color: palette.tickCheck,
            marginRight: '6px',
            width: '16px',
        },
        '& .shopping-for': {
            flex: 1,
        },
        '& .cancel-button-wrapper': {
            flex: 'none',
        },
        '& button': {
            fontFamily: typography.fontFamily,
            boxSizing: 'border-box',
            WebkitFontSmoothing: 'antialiased',
            fontWeight: '700',
            width: 'auto',
            minWidth: 150,
            fontSize: '1em',
            textTransform: 'none',
            borderRadius: '20px',
            padding: '6px 40px',
            boxShadow: 'none',
            // primary button
            color: palette.common?.white || '#fff',
            backgroundColor: palette.cms?.primary || '#a1001a',
            border: `1px solid ${palette.cms?.primary || '#a1001a'}`,
            '&:hover': {
                color: palette.common?.white || '#fff',
                border: `1px solid ${palette.cms?.primary_hover || '#d73833'}`,
                backgroundColor: palette.cms?.primary_hover || '#d73833',
            },
            '&[disabled]': {
                color: palette.grey8,
                border: `1px solid ${palette.grey['300']}`,
                backgroundColor: palette.grey['300'],
            },
            '&.small': {
                padding: '2px 12px !important',
                minWidth: 'auto',
                fontSize: '0.9em !important',
            },
            // secondary button
            '&.secondary': {
                color: palette.cms?.secondary || '#161616',
                backgroundColor: palette.common?.white || '#fff',
                border: `1px solid ${palette.cms?.secondary || '#161616'}`,
                '&:hover': {
                    color: palette.common?.white || '#fff',
                    border: `1px solid ${palette.cms?.secondary || '#161616'}`,
                    backgroundColor: palette.cms?.secondary || '#161616',
                },
                '&[disabled]': {
                    color: palette.grey8,
                    border: `1px solid ${palette.grey['300']}`,
                    backgroundColor: palette.grey['300'],
                },
            },
        },
    },
    giftListSiteBannerV2: {
        maxWidth: 'max-content',
        backgroundColor: palette.cms?.white || '#ffffff',
        boxShadow: ' 2px 2px 4px rgba(47, 47, 47, 0.15)',
        '& .shopping-for': {
            color: palette.cms?.black || '#2f2f2f',
        },
        '& button': {
            color: palette.cms?.white || '#ffffff !important',
            backgroundColor: palette.cms?.black || '#2f2f2f !important',
            marginLeft: '16px',
            borderRadius: 'inherit',
        },

    },
});

const {
    common: {
        commonSelectors: { getContactId, getIsAuthenticatedStatus },
    },
} = memberDucks;

class ShopTheSiteBanner extends React.Component {
    handleCancelShopTheSiteClicked = (giftListURL) => {
        const { contactId, history } = this.props;
        resetShopTheSiteStorage(contactId);
        const data = {
            firstName: '',
            lastName: '',
        };
        this.setState({ ...data });
        if (giftListURL) history.push(giftListURL);
        else history.push('/account/gift-list');
    };

    isBannerNotAvailable = () => {
        const hiddenURLs = [
            '\\/gifthistory-home-bakery', // WLF
            '\\/gifthistory-home-steaks', // STY
            '\\/gifthistory-home-gift-baskets', // H&D
            '\\/gifthistory-home-cookies', // CCO
            '\\/account\\/?.*',
            '\\/checkout\\/.*',
        ];
        const pathname = typeof window !== 'undefined' ? window.location.pathname : '/';
        let isHidden = false;
        hiddenURLs.forEach((url) => {
            const reg = new RegExp(url);
            if (reg.test(pathname) && !isHidden) {
                isHidden = true;
            }
        });
        return isHidden;
    };

    render() {
        // eslint-disable-next-line object-curly-newline
        const { isAuthenticated, classes, contactId, shopTheSite, featureFlags, flags } = this.props;
        const recipientInfo = getShopTheSite(contactId) || {};
        let { firstname: firstName, lastname: lastName, giftListURL } = recipientInfo;
        if (isEmpty(recipientInfo) && !isEmpty(shopTheSite)) {
            ({ firstname: firstName, lastname: lastName, giftListURL } = shopTheSite);
        }
        if (!isAuthenticated || !contactId || !firstName || !lastName || this.isBannerNotAvailable()) return null;
        const giftListUserV2 = featureFlags['is-giftlist-v2-enabled'] && flags?.experimentGiftListV2 === 'variant';
        return (

            <div className={giftListUserV2 ? `${classes.giftListSiteBannerV2} ${classes.giftListShopTheSiteBanner}` : classes.giftListShopTheSiteBanner}>
                {giftListUserV2 ? <img src="https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/bltb580ea1d412ba744/63353cfac78dc224975c3646/shop_the_site.svg?auto=webp" alt="shop site" />
                    : <FullCircleIcon className="full-circle-icon" />}
                <div className="shopping-for">
                    Shopping for:{' '}
                    <b>
                        {firstName} {lastName}
                    </b>
                </div>
                <Button
                    className="cancel-button-wrapper secondary small"
                    variant="contained"
                    size="small"
                    onClick={() => this.handleCancelShopTheSiteClicked(giftListURL)}
                >
                    {giftListUserV2 ? 'Back To Gift List' : 'Cancel'}
                </Button>
            </div>
        );
    }
}

ShopTheSiteBanner.defaultProps = {
    contactId: '',
    shopTheSite: {},
    flags: {},
};

ShopTheSiteBanner.propTypes = {
    isAuthenticated: bool.isRequired,
    classes: object.isRequired,
    history: object.isRequired,
    contactId: string,
    shopTheSite: shape({
        firstname: string,
        lastname: string,
    }),
    featureFlags: object.isRequired,
    flags: object,

};

function mapStateToProps(state) {
    return {
        contactId: getContactId(state),
        shopTheSite: state.shopTheSite ?? {}, // For unit test only
        isAuthenticated: getIsAuthenticatedStatus(state) ?? false,
        featureFlags: getFeatureFlags(state),
        activeABTests: getActiveABTests(state),
    };
}

const enhance = compose(
    withLDConsumer(),
    withRouter,
    withStyles(styles, { withTheme: true }),
    connect(mapStateToProps, null),
);
export default enhance(ShopTheSiteBanner);
