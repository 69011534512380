/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { shape, array } from 'prop-types';
import LinkOrATag from '../GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import { getBrand, getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { handleDoNotSellMyInformationClick } from '../../../helpers/tracking/common/commonTrackingHelpers';

const useStyles = makeStyles((theme) => ({
    legalLinks: ({ altColor }) => ({
        display: 'flex',
        width: 'fit-content',
        margin: '0 auto',
        fontSize: '.875rem',
        color: altColor,
        '& a': {
            color: altColor,
            textUnderlineOffset: '3px',
            textDecoration: 'underline !important',
        },
        '& span::before': {
            content: '"-"',
            display: 'inline-block',
            margin: '0 5px',
            position: 'relative',
        },
        '& a::after': {
            content: '"|"',
            display: 'inline-block',
            margin: '0 5px',
            position: 'relative',
        },
        '& a:last-of-type::after': {
            content: '""',
            display: 'none',
            margin: '0',
        },
        '@media (max-width: 1024px)': {
            display: 'block',
            textAlign: 'center',
            padding: '.5rem 0 1rem',
            color: theme.palette?.legalLinks || theme.palette.black,
            fontSize: '0.75rem',
            '& a': {
                color: `${theme.palette?.legalLinks || theme.palette.black} !important`,
            },
        },
    }),
    legalBlock: {
        '& > a': {
            '&:after': {
                margin: '0px 5px!important',
                content: '"|"!important',
                display: 'inline-block!important',
                position: 'relative!important',
            },
        },
    },
}));

const LegalLinks = ({ data }) => {
    const brand = useSelector(getBrand);
    const domain = brand.domain;
    const getAltColor = () => {
        let color = '#1f1f1b'; // default
        data.alternate_text_color?.value?.forEach((o) => {
            if (o.key === domain) color = o.value;
        });
        return color;
    };
    const altColor = getAltColor();
    const classes = useStyles({ altColor });
    const ffIstrustArcRenderDiv  = useSelector(getFeatureFlag('is-trustarc-div-model-enabled'));
    const legalRenderArray = [];

    const sitemapArray = data.sitemap_links.value;
    const legalArray = data.modular_blocks;

    const buildSitemapLink = () => {
        const sitemapLink = sitemapArray.find((t) => t.key === domain)?.value;
        if (sitemapLink) return legalRenderArray.push(<LinkOrATag href={sitemapLink}>Site Map</LinkOrATag>);
        return null;
    };
    const doNotSell = (href) => {
        if (href?.includes('submit-irm.trustarc.com')) handleDoNotSellMyInformationClick();
    };
    const entity = (block) => (domain === 'florists' ? block.copy?.copy?.replace('1-800-Flowers.com, Inc.', 'Florists.com, LLC') : block?.copy?.copy);
    const buildLegalBlocks = () => {
        let link = '';
        legalArray.forEach((block) => {
            switch (Object.keys(block)[0]) {
                case 'copy':
                    legalRenderArray.push(<span>{entity(block)}</span>);
                    break;
                case 'link':
                    if (block.link?.link?.href?.includes('cookie-preferences')) {
                        link = (<div id="teconsent" className={classes.legalBlock} />);
                    } else if (ffIstrustArcRenderDiv && block.link?.link?.href?.includes('submit-irm.trustarc.com')) {
                        link = (<div className={classes.legalBlock} id="irmlink" />);
                    } else {
                        link = (
                            <LinkOrATag
                                href={block.link.link.href}
                                indexKey={block.link.link.href}
                                trackingEventAction={block.link.tracking_event_action || ''}
                                trackingEventCategory={block.link.tracking_event_category || ''}
                                trackingEventLabel={block.link.tracking_event_label || ''}
                                isTrackEventEnabled
                                doNotSell={doNotSell}
                            >
                                {block.link.link.title}
                            </LinkOrATag>
                        );
                    }
                    legalRenderArray.push(link);
                    break;
                case 'sitemap':
                    buildSitemapLink();
                    break;
                default:
                    break;
            }
        });
    };

    const renderLegalFooter = () => {
        buildLegalBlocks();
        return legalRenderArray;
    };

    return <div className={classes.legalLinks}>{renderLegalFooter()}</div>;
};

LegalLinks.propTypes = {
    data: shape({
        modular_blocks: array.isRequired,
    }),
};
LegalLinks.defaultProps = {
    data: {},
};

export default LegalLinks;
